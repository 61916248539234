import {useEffect, useState} from "react";
import dayjs from "dayjs";
import 'dayjs/locale/fr'
import {AuditLog} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";

interface AuditLogProps {
    logs:[]
}

const IssuerAuditLog = (props:AuditLogProps) => {
    const {logs} = props
    const [auditLogs,setAuditLogs]:any = useState([])
    const {i18n} = useTranslation('common')

    useEffect(()=>{
        let tmp:any = []
        logs.forEach((log:any)=>{
            tmp.push({
                timestamp:dayjs(log.aud_time).locale(i18n.language).format('DD MMM YYYY, HH:mm'),
                user:log.aud_usr_id,
                logDescription:log.aud_action,
                reference:log.aud_id
            })
        })
        setAuditLogs(tmp)
    },[logs,i18n.language])

    return (
        <div className="mt-[32px]">
            <AuditLog logDetails={auditLogs}/>
        </div>
    )
}

export default IssuerAuditLog
