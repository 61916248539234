import DashboardBaseLayout from "../components/DashboardBaseLayout";
import SearchField from "../components/SearchField";
import VDivider from "../components/VDivider";
import {useContext, useEffect, useState} from "react";
import {axiosSend} from "../utils/request";
import SidePanel from "../components/SidePanel/SidePanel";
import dayjs from "dayjs";
import AddUserContent from "../components/AddUserContent/AddUserContent";
import AssignUserActions from "../components/AssignUserActions/AssignUserActions";
import BackButton from "../components/BackButton";
import UserContext from "../utils/userContext";
import UnauthorisedAccess from "../components/UnauthorisedAccess";
import {activeStatus, appName} from "../helpers";
import EditUserContent from "../components/EditUserContent/EditUserContent";
import {AuditLog, Button, ButtonOutline, DisplayField, LoadingSpinner, Tab, UsersTable} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";

const Users = () => {
    const {t} = useTranslation('common')
    document.title = `${appName} - ${t('common:code_maintenance.users')}`
    const loggedInDets:any = useContext(UserContext)
    const [loading,setLoading] = useState(true)
    const [users,setUsers]:any = useState([])
    const [usersOri,setUsersOri]:any = useState([])
    const [srchQry,setSrchQry] = useState('')
    const [userDetsPane,setUserDetsPane] = useState(false)
    const [userDets,setUserDets]:any = useState({})
    const [userDetsPage,setUserDetsPage] = useState(false)
    const [dctnUsr,setDctnUsr] = useState(false)
    const [disableBtns,setDisableBtns] = useState(false)
    const [userAuditLogs,setUserAuditLogs] = useState([])
    const [displayedContent,setDisplayedContent] = useState('userDets')
    const [panelBackBtn,setPanelBackBtn]:any = useState('')
    const [addUserPanel,setAddUserPanel] = useState(false)
    const [hasAccess,setHasAccess] = useState(true)
    const [userActionStep,setUserActionStep] = useState(1)
    const [addStep,setAddStep] = useState(1)
    const [isNotIssuer,setIsNotIssuer] = useState(true)

    function loadUsers(){
        axiosSend({
            url:'/fx/admin/users',
            method:'GET'
        }).then(res => {
            if (res.data.Status === 'SUCC' && res.data.data.length > 0){
                let tmp:any = []
                res.data.data.forEach((user:any)=>{
                    tmp.push({
                        name:`${user.usr_name1} ${user.usr_name2}`,
                        organisation:user.organization,
                        email:user.usr_email,
                        role:user.role,
                        status:activeStatus(user.usr_act_status,t),
                        userId:user.usr_id
                    })
                })
                setUsers(tmp)
                setUsersOri(tmp) //this is to handle search
                setLoading(false)
            }
        })
    }

    useEffect(()=>{
        if (loggedInDets && Object.keys(loggedInDets).length > 0){
            let usrHasAccess = false
            loggedInDets.apps.forEach((app:any)=>{
                if (app.APP_CAM){
                    usrHasAccess = true
                    loadUsers()
                }
                setHasAccess(usrHasAccess)
            })
        }
    },[loggedInDets])


    function getUserDets(userId:string){
        axiosSend({
            url:`/fx/admin/users/${userId}`,
            method:'GET'
        }).then(res => {
            if (res.data.Status === 'SUCC'){
                setUserDets(res.data)

                //generate user's audit logs
                let tmp:any = []
                res.data.logs.forEach((log:any) => {
                    tmp.push({
                        timestamp:dayjs(log.aud_time).format('D MMM YYYY, HH:mm'),
                        logDescription:log.aud_action,
                        user:log.aud_usr_id,
                        reference:log.aud_id
                    })
                })
                setUserAuditLogs(tmp)

                setUserDetsPage(true)
                setUserDetsPane(true)
                setDisplayedContent('userDets')
            }
        })
    }

    function search(qry:string){
        setSrchQry(qry)
        setUsers(usersOri.filter((user:any)=>(user.name.toLowerCase().includes(qry.toLowerCase()))))
    }

    function deactivateUser(userId:string){
        setDctnUsr(true)
        setDisableBtns(true)
        axiosSend({
            url:`/fx/admin/users/deact/${userId}`,
            method:"PATCH",
            data:{
                action:'savUser'
            }
        }).then(res => {
            setDctnUsr(false)
            setDisableBtns(false)
            loadUsers()
            getUserDets(userId)
        }).catch(err => {
            console.log(err.response)
            setDctnUsr(false)
            setDisableBtns(false)
        })
    }

    function activateUser(userId:string){
        setDctnUsr(true)
        setDisableBtns(true)
        axiosSend({
            url:`/fx/admin/users/act/${userId}`,
            method:'PATCH',
            data:{
                action:'savUser'
            }
        }).then(res => {
            setDctnUsr(false)
            setDisableBtns(false)
            loadUsers()
            getUserDets(userId)
        }).catch(err => {
            console.log(err.response)
            setDctnUsr(false)
            setDisableBtns(false)
        })
    }

    useEffect(()=>{
        if (Object.keys(userDets).length > 0 && userDets.roles.length > 0){
            if (displayedContent === 'userDets'){
                setPanelBackBtn(`${userDets.data[0].usr_name1} ${userDets.data[0].usr_name2} (${userDets.roles[0].role_name})`)
            }
            if (displayedContent === 'editUser' || displayedContent === 'assignActions'){
                setPanelBackBtn(
                    <BackButton disabled={userActionStep === 2} onClick={()=>{setDisplayedContent('userDets')}}/>
                )
            }
            const role = userDets.roles.find((role:any) => role.role_code === 'ISS_SUP_BROKER')
            setIsNotIssuer(role === undefined)
        }

    },[userActionStep,displayedContent, userDets])

    function UserDetsContent(){
        return (
            <>
                {userDetsPage &&
                    <div className="flex flex-col flex-1 gap-[40px] mt-[40px]">
                        <div className="flex flex-col flex-1 gap-[24px] mb-[40px]">
                            {isNotIssuer &&
                                <DisplayField label={t('common:dashboard.address')}>
                                    <div className="flex flex-col gap-[8px]">
                                        {userDets.data[0].usr_address &&
                                            <p>{userDets.data[0].usr_address}</p>
                                        }
                                        {userDets.data[0].usr_addr_city &&
                                            <p>{userDets.data[0].usr_addr_city}</p>
                                        }
                                        {userDets.data[0].usr_addr_province &&
                                            <p>{userDets.data[0].usr_addr_province}</p>
                                        }
                                        {userDets.data[0].usr_country &&
                                            <p>{userDets.data[0].usr_country}</p>
                                        }
                                        {userDets.data[0].usr_addr_post_code &&
                                            <p>{userDets.data[0].usr_addr_post_code}</p>
                                        }
                                    </div>
                                </DisplayField>
                            }
                            <DisplayField label={t('common:general.phone')}>
                                {userDets.data[0].usr_ph_no ? userDets.data[0].usr_ph_no : '-'}
                            </DisplayField>
                            {isNotIssuer && <DisplayField label={t('common:general.mobile')}>
                                {userDets.data[0].usr_mb_no ? userDets.data[0].usr_mb_no : '-'}
                            </DisplayField>
                            }
                            <DisplayField label={t('common:login.email')}>
                                {userDets.data[0].usr_email ? userDets.data[0].usr_email : '-'}
                            </DisplayField>
                            <DisplayField label={t('common:dashboard.organisation')}>
                                {userDets.data[0].organization ? userDets.data[0].organization : '-'}
                            </DisplayField>
                            <DisplayField label={t('common:users.user_role')}>
                                {userDets.roles.length > 0 ? userDets.roles[0].role_name : '-'}
                            </DisplayField>
                        </div>
                        <div className="flex gap-[8px] items-center">
                            <Button size="lg" variant="primary" disabled={disableBtns} onClick={()=>{
                                setDisplayedContent('editUser')
                                setUserActionStep(1)
                            }}>{t('common:users.edit_user')}</Button>
                            {userDets.data[0].usr_act_status.toUpperCase() === 'ACTIVE' && <ButtonOutline size="lg" variant="error" onClick={()=>{deactivateUser(userDets.data[0].usr_id)}} disabled={disableBtns}>{!dctnUsr ? t('common:general.deactivate') : <LoadingSpinner variant={"primary"}/>}</ButtonOutline>}
                            {userDets.data[0].usr_act_status.toUpperCase() === 'INACTIVE' && <ButtonOutline size="lg" variant="success" onClick={()=>{activateUser(userDets.data[0].usr_id)}} disabled={disableBtns}>{!dctnUsr ? t('common:general.reactivate') : <LoadingSpinner variant={"primary"}/>}</ButtonOutline>}
                            {isNotIssuer && <ButtonOutline size="lg" width="fit-content" disabled={disableBtns} onClick={()=>{
                                setDisplayedContent('assignActions')
                                setUserActionStep(1)
                            }}><i className="icon-settings"/></ButtonOutline>}
                        </div>
                    </div>
                }
            </>
        )
    }

    const tabs = [
        {
            tabName:t('common:users.user_dets'),
            tabComponent: <UserDetsContent/>
        },
        {
            tabName:t('common:general.audit_log'),
            tabComponent: <AuditLog logDetails={userAuditLogs}/>
        },
    ]

    if (!hasAccess){
        return (
            <DashboardBaseLayout pageName="liquidity-providers">
                <UnauthorisedAccess/>
            </DashboardBaseLayout>
        )
    }

    return (
        <DashboardBaseLayout pageName="users">
            <div className="flex justify-between items-center mb-[16px]">
                <h6 className="heading-6-bold font-bold">{t('common:code_maintenance.users')}</h6>
                <div className="flex justify-between items-center gap-[24px]">
                    {Object.keys(loggedInDets).length > 0 && loggedInDets.userActions.includes('savUser') &&
                        <>
                            <div>
                                <Button variant="primary" size="md" onClick={()=>{setAddUserPanel(true)}}>{t('common:users.add_user')}</Button>
                            </div>
                            <VDivider height={43}/>
                        </>
                    }
                    <SearchField placeholder={t('common:users.search_users')} onChange={(e)=>{search(e.target.value)}} value={srchQry}/>
                </div>
            </div>
            {loading &&
                <div className="bg-white rounded-[8px] h-[450px] flex justify-center items-center">
                    <LoadingSpinner variant={"primary"}/>
                </div>
            }
            {!loading && users.length > 0 &&
                <UsersTable users={users} callback={(user:any)=>{getUserDets(user.userId)}} labelOverrides={{
                    name:t('common:general.name'),
                    role:t('common:general.role'),
                    email:t('common:login.email'),
                    status:t('common:general.status'),
                    organisation:t('common:dashboard.organisation'),
                    emptyMsg:t('common:users.empty_msg'),
                }}/>
            }

            {/*  USER DETAILS PANEL  */}
            {userDetsPane &&
                <SidePanel closeFxn={()=>{setUserDetsPane(false)}} backBtnArea={panelBackBtn}>
                    {displayedContent === 'userDets' && <Tab tabs={tabs}/>}
                    {displayedContent === 'editUser' && <EditUserContent userDets={userDets} setStep={(e)=>{setUserActionStep(e)}} step={userActionStep}/>} {/*TODO refresh user data*/}
                    {displayedContent === 'assignActions' && <AssignUserActions userDets={userDets} setStep={(e)=>{setUserActionStep(e)}} step={userActionStep}/>}
                </SidePanel>
            }

            {/* ADD USER PANEL*/}
            {addUserPanel &&
                <SidePanel closeFxn={()=>{setAddUserPanel(false)}} backBtnArea={<BackButton disabled={true}/>} title={addStep === 1 ? t('common:users.add_user') : ''}>
                    <AddUserContent setStep={(a)=>{setAddStep(a)}} step={addStep}/>
                </SidePanel>
            }
        </DashboardBaseLayout>
    )
}

export default Users
