import {AnimatePresence, motion} from "framer-motion";
import HDivider from "../HDivider";
import {SidePanelProps} from "./type";
import {useEffect, useState} from "react";

const SidePanel = (props:SidePanelProps) => {
    const {backBtnArea,closeFxn,children,title,subtext,step} = props
    const [openPanel,setOpenPanel] = useState(true)

    useEffect(()=>{
        (document.querySelector('body') as HTMLElement).classList.add('side-panel-open')
    },[])

    useEffect(()=>{
        let overlay = document.querySelector('.trans-overlay') as HTMLElement
        overlay.addEventListener('click',function(e:any){
            if (e.target.contains(overlay)){
                setOpenPanel(false)
                closeFxn(false);
                (document.querySelector('body') as HTMLElement).classList.remove('side-panel-open')
            }
        })
    },[openPanel])

    return (
        <AnimatePresence>
            {openPanel &&
                <div className="fixed bg-black/40 inset-0 z-[999] trans-overlay">
                    <motion.div className="absolute h-full w-[510px] right-0 top-0 bg-white"
                                animate={{
                                    x:0,
                                    opacity:1
                                }}
                                initial={{
                                    x:100,
                                    opacity:0
                                }}
                                exit={{
                                    x:100,
                                    opacity:0
                                }}
                                transition={{
                                    duration:0.3
                                }}
                    >
                        <div className="p-[32px] flex justify-between items-center">
                            {backBtnArea}
                            <button onClick={()=> {
                                (document as any).querySelector('body').classList.remove('side-panel-open')
                                setOpenPanel(false)
                                closeFxn(false)
                            }}>
                                <i className="icon-times"/>
                            </button>
                        </div>
                        <HDivider twBorderColor="border-neutral-100"/>
                        <div className="side-panel-body">
                            {title &&
                                <div className="mb-[40px]">
                                    <div className="flex justify-between items-center">
                                        <div className="flex flex-col gap-[4px]">
                                            <p className="font-bold text-[19px]">{title}</p>
                                            {subtext && <p className="text-[14px]">{subtext}</p>}
                                        </div>
                                        {step && <p>{step}</p>}
                                    </div>
                                </div>
                            }
                            {children}
                        </div>
                    </motion.div>
                </div>
            }
        </AnimatePresence>
    )
}

export default SidePanel
