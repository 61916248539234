import {createContext, useEffect, useState} from "react";
import {useUpdateUser} from "./useHooks";
// import {user as userType} from "./type";

const UserContext = createContext({
    profile:{}
})

export const UserContextProvider = (props:any) => {
    const {children} = props
    // const [userProfile, setUserProfile]: any = useState(null)
    const [usrProf,setUsrProf]:any = useState({})

    //user information
    if (!useUpdateUser()){
        window.location.replace('/')
    }
    const [userData,updateuser]:any = useUpdateUser()

    const unprotectedPages = ['/','/forgot-password','/set-password']

    useEffect(()=>{
        if (!unprotectedPages.includes(window.location.pathname.toLowerCase()) && userData){
            if (userData.profile.length === 0){
                window.location.replace('/')
                return
            }
            setUsrProf(userData)
        }

    },[userData])

    return (
        <UserContext.Provider value={usrProf}>
            {children}
        </UserContext.Provider>
    )
}

export default UserContext
