import HDivider from "../HDivider";
import {Button, DisplayField} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";


interface BrokerDetsContentProps{
    brokerDets:any,
    editBtnFxn:()=>void
}

const IssuerDetsContent = (props:BrokerDetsContentProps) => {
    const {brokerDets,editBtnFxn} = props
    const {t} = useTranslation('common')

    return (
        <>
            {/*BASIC INFO*/}
            <div className="flex gap-[24px] flex-col mt-[32px]">
                <h6 className="font-bold">{t('common:liquidity_providers.basic_info')}</h6>
                <HDivider twBorderColor="border-neutral-900"/>
                <DisplayField label={`${t('common:issuer.issuer_name')}`}>
                    {brokerDets.data[0].org_name1}
                </DisplayField>
                <DisplayField label={t('common:general.country')}>
                    <span className="flex gap-2 items-center">
                        <img src={brokerDets.data[0].cty_flag_name} alt=""/>
                        {brokerDets.data[0].cty_name}
                    </span>
                </DisplayField>
                <DisplayField label={t('common:login.email')}>
                    {brokerDets.data[0].org_email ? brokerDets.data[0].org_email : '-'}
                </DisplayField>
                {/*<DisplayField label={t('common:general.phone')}>
                    {brokerDets.data[0].org_mph_no ? brokerDets.data[0].org_mph_no : '-'}
                </DisplayField>*/}
                <DisplayField label={t('common:brokers.tin')}>
                    {brokerDets.data[0].org_tin ? brokerDets.data[0].org_tin : '-'}
                </DisplayField>
            </div>
            {/*CONTACT 1 INFO*/}
            <div className="flex gap-[24px] flex-col mt-[24px]">
                <h6 className="font-bold">{t('common:brokers.contact_person')} #1</h6>
                <HDivider twBorderColor="border-neutral-900"/>
                <DisplayField label={t('common:general.name')}>
                    {brokerDets.data[0].org_con1_name ? brokerDets.data[0].org_con1_name : '-'}
                </DisplayField>
                <DisplayField label={t('common:login.email')}>
                    {brokerDets.data[0].org_con1_email ? brokerDets.data[0].org_con1_email : '-'}
                </DisplayField>
                <DisplayField label={t('common:general.phone')}>
                    {brokerDets.data[0].org_con1_mb_no ? brokerDets.data[0].org_con1_mb_no : '-'}
                </DisplayField>
            </div>
            <div className="mt-[40px]">
                <Button size="lg" variant="primary" onClick={()=>{editBtnFxn()}}>{t('common:general.edit')}</Button>
            </div>
        </>
    )
}

export default IssuerDetsContent
