import DashboardBaseLayout from "../components/DashboardBaseLayout";
import VDivider from "../components/VDivider";
import SearchField from "../components/SearchField";
import {useContext, useEffect, useState} from "react";
import {axiosSend} from "../utils/request";
import SidePanel from "../components/SidePanel/SidePanel";
import BrokerDetsContent from "../components/BrokerDetsContent/BrokerDets";
import BrokerBankAccounts from "../components/BrokerDetsContent/BrokerBankAccounts";
import BrokerAuditLog from "../components/BrokerDetsContent/BrokerAuditLog";
import EditBankAccount from "../components/BrokerDetsContent/EditBankAccount";
import BackButton from "../components/BackButton";
import AddBroker from "../components/AddBroker/AddBroker";
import EditBrokerContent from "../components/BrokerDetsContent/EditBrokerContent";
import UserContext from "../utils/userContext";
import UnauthorisedAccess from "../components/UnauthorisedAccess";
import AddBankAccount from "../components/BrokerDetsContent/AddBankAccount";
import {appName} from "../helpers";
import {BrokersTable, Button, LoadingSpinner, Tab} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";

const Brokers = () => {
    const {t} = useTranslation('common')
    document.title = `${appName} - ${t('common:code_maintenance.brokers')}`
    const [loading,setLoading] = useState(true)
    const userDets:any = useContext(UserContext)
    const [orgs,setOrgs]:any = useState([])
    const [orgsOri,setOrgsOri]:any = useState([])
    const [brokerDetsPanel,setBrokerDetsPanel] = useState(false)
    const [brokerDets,setBrokerDets]:any = useState({})
    const [screen,setScreen] = useState('brokerDets')
    const [auditLog,setAuditLog]:any = useState([])
    const [acctDets,setAcctDets]:any = useState({})
    const [addBrokerPnl,setAddBrokerPnl] = useState(false)
    const [addBrokerStep,setAddBrokerStep] = useState(1)
    const [disabledAddBrkrBackBtnState,setDisabledAddBrkrBackBtnState] = useState(true)
    const [addBrokerStepTitle,setAddBrokerStepTitle] = useState('Basic Information')
    const [srchQry,setSrchQry] = useState('')
    const [hasAccess,setHasAccess] = useState(true)
    const [editBankStep,setEditBankStep] = useState(1)

    useEffect(()=>{
        if (addBrokerStep > 1){
            setDisabledAddBrkrBackBtnState(false)
        }else{
            setDisabledAddBrkrBackBtnState(true)
        }
    },[addBrokerStep])

    function loadBrokers(){
        axiosSend({
            url:'/fx/admin/org/',
            method:'GET'
        }).then(res => {
            if (res.data.Status === 'SUCC'){
                const tmp:any = []
                res.data.data.forEach((org:any)=>{
                    if (!org.broker_type){
                        tmp.push({
                            brokerId:org.org_code,
                            name:org.org_name1+' '+org.org_name2,
                            email:org.org_email,
                            phone:org.org_mph_no,
                            country:org.cty_name,
                            countryFlag:org.cty_flag_name
                        })
                    }
                })
                setOrgs(tmp)
                setOrgsOri(tmp)
            }
            setLoading(false)
        })
    }

    useEffect(()=>{
        if (userDets && Object.keys(userDets).length > 0){
            let usrHasAccess = false
            userDets.apps.forEach((app:any)=>{
                if (app.APP_CAM){
                    usrHasAccess = true
                    loadBrokers()
                }
                setHasAccess(usrHasAccess)
            })
        }
    },[userDets])

    function srchBrokers(qry:string){
        setSrchQry(qry)
        setOrgs(orgsOri.filter((org:any)=>org.name.toLowerCase().includes(qry.toLowerCase())))
    }

    function getBrokerDets(brokerId:string){
        //load broker details
        axiosSend({
            url:`/fx/admin/org/${brokerId}`,
            method:'GET'
        }).then(res => {
            if (res.data.Status === 'SUCC'){
                //populate the audit log
                setAuditLog(res.data.logs)

                //load broker bank accounts
                axiosSend({
                    url:`/fx/admin/part-acc-v2/${brokerId}`,
                    method:'GET'
                }).then(bnkRes => {
                    //append the bank data to the brokers data as a key
                    res.data.bank_accts = bnkRes.data.data
                    setBrokerDets(res.data)
                    setBrokerDetsPanel(true)
                })
            }
        })
    }

    function backBtn(pnlScr:string){
        if (pnlScr === 'brokerDets') return brokerDets.data[0].org_name1+' '+brokerDets.data[0].org_name2
        if (pnlScr === 'addBankAcct' || pnlScr === 'editBroker') return (
            <BackButton disabled={false} onClick={()=>{setScreen('brokerDets')}}/>
        )
        if (pnlScr === 'editBankAcct'){
            return (<BackButton disabled={editBankStep === 2} onClick={()=>{setScreen('brokerDets')}}/>)
        }
    }

    const tabs = [
        {
            tabName:t('common:brokers.broker_dets'),
            tabComponent: <BrokerDetsContent brokerDets={brokerDets} editBtnFxn={()=>{setScreen('editBroker')}}/>
        },
        {
            tabName:t('common:brokers.bank_accts'),
            tabComponent: <BrokerBankAccounts brokerDets={brokerDets} addAccountFxn={()=>{setScreen('addBankAcct')}} refreshBrokerDets={(e)=>{getBrokerDets(e)}} editBankAccount={(e)=>{
                setAcctDets(e)
                setScreen('editBankAcct')
            }}/>
        },
        {
            tabName:t('common:brokers.audit'),
            tabComponent: <BrokerAuditLog logs={auditLog}/>
        }
    ]

    if (!hasAccess){
        return (
            <DashboardBaseLayout pageName="liquidity-providers">
                <UnauthorisedAccess/>
            </DashboardBaseLayout>
        )
    }

    return (
        <DashboardBaseLayout pageName="brokers">
            <div className="flex justify-between items-center mb-[16px]">
                <h6 className="heading-6-bold font-bold">{t('common:code_maintenance.brokers')}</h6>
                <div className="flex justify-between items-center gap-[24px]">
                    {Object.keys(userDets).length && userDets.userActions.includes('savOrg') &&
                    <>
                        <div>
                            <Button variant="primary" size="md" onClick={()=>{
                                setAddBrokerPnl(true)
                                setAddBrokerStep(1)}}>{t('common:brokers.add_broker')}</Button>
                        </div>
                        <VDivider height={43}/>
                    </>
                    }
                    <SearchField placeholder={t('common:brokers.search_brokers')} value={srchQry} onChange={(e)=>{srchBrokers(e.target.value)}}/>
                </div>
            </div>
            {loading &&
                <div className="bg-white rounded-[8px] h-[450px] flex justify-center items-center">
                    <LoadingSpinner variant={"primary"}/>
                </div>
            }
            {!loading &&
                <BrokersTable brokers={orgs} callback={(e)=>{getBrokerDets(e.brokerId)}} labelOverrides={{
                    name:t('common:general.name'),
                    email:t('common:login.email'),
                    country:t('common:general.country'),
                    phone:t('common:general.phone'),
                    emptyMsg:t('common:brokers.empty_msg'),
                }}/>
            }

            {brokerDetsPanel &&
                <SidePanel closeFxn={()=>{
                    setBrokerDetsPanel(false)
                    setScreen('brokerDets')
                }} backBtnArea={backBtn(screen)}>
                    {screen === 'brokerDets' &&
                        <Tab tabs={tabs}/>
                    }
                    {screen === 'addBankAcct' &&
                        <AddBankAccount entityId={brokerDets.data[0].org_code}/>
                    }
                    {screen === 'editBankAcct' &&
                        <EditBankAccount acctDets={acctDets} step={editBankStep} setStep={(e)=>{setEditBankStep(e)}}/>
                    }
                    {screen === 'editBroker' &&
                        <EditBrokerContent brokerDets={brokerDets.data[0]}/>
                    }
                </SidePanel>
            }
            {addBrokerPnl &&
                <SidePanel closeFxn={()=>{setAddBrokerPnl(false)}} backBtnArea={<BackButton onClick={()=>{setAddBrokerStep(addBrokerStep - 1)}} disabled={disabledAddBrkrBackBtnState || addBrokerStep > 3}/>} title={addBrokerStep <= 3 ? t('common:brokers.add_broker') : ''} subtext={addBrokerStepTitle}>
                    <AddBroker stepString={(e)=>{setAddBrokerStep(e)}} stepTitle={(e)=>{setAddBrokerStepTitle(e)}} step={addBrokerStep}/>
                </SidePanel>
            }
        </DashboardBaseLayout>
    )
}

export default Brokers
