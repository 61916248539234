import DashboardBaseLayout from "../components/DashboardBaseLayout";
import VDivider from "../components/VDivider";
import SearchField from "../components/SearchField";
import {useContext, useEffect, useState} from "react";
import {axiosSend} from "../utils/request";
import AddProvider from "../components/ProvidersContent/AddProvider";
import SidePanel from "../components/SidePanel/SidePanel";
import BackButton from "../components/BackButton";
import ProviderDets from "../components/ProvidersContent/ProviderDets";
import LPRateHistory from "../components/ProvidersContent/LPRateHistory";
import dayjs from "dayjs";
import ProviderBankAccounts from "../components/ProvidersContent/ProviderBankAccounts";
import EditProviderBankAcct from "../components/ProvidersContent/EditProviderBankAcct";
import EditProvider from "../components/ProvidersContent/EditProvider";
import ProviderAddRates from "../components/ProvidersContent/ProviderAddRates";
import AddProviderBank from "../components/ProvidersContent/AddProviderBank";
import UserContext from "../utils/userContext";
import UnauthorisedAccess from "../components/UnauthorisedAccess";
import {activeStatus, appName, ucFirst} from "../helpers";
import {AuditLog, Button, LoadingSpinner, ProvidersTable, Tab} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";
import 'dayjs/locale/fr'

const LiquidityProviders = () => {
    const {t,i18n} = useTranslation('common')
    document.title = `${appName} - ${'common:code_maintenance.liquidity_providers'}`
    const userDets:any = useContext(UserContext)
    const [loading,setLoading] = useState(true)
    const [provs,setProvs]:any = useState([])
    const [provsOri,setProvsOri]:any = useState([])
    const [srchQry,setSrchQry] = useState('')
    const [provDets,setProvDets]:any = useState({})
    const [showAdd,setShowAdd] = useState(false)
    const [addProvStep,setAddProvStep] = useState(1)
    const [editProvStep,setEditProvStep] = useState(1)
    const [addBankStep,setAddBankStep] = useState(1)
    const [addRateStep,setAddRateStep] = useState(1)
    const [disabledBack,setDisabledBack] = useState(true)
    const [showPanelTitle,setShowPanelTitle] = useState(true)
    const [showDetsPanelTitle,setShowDetsPanelTitle] = useState(true)
    const [showProvDets,setShowProvDets] = useState(false)
    const [ratesHistory,setRatesHistory]:any = useState({
        active:[],
        archived:[]
    })
    const [logs,setLogs]:any = useState([])
    const [detsView,setDetsView] = useState('prov-dets')
    const [bankAcctDets,setBankAcctDets] = useState({})
    const [hasAccess,setHasAccess] = useState(true)
    const [editBankStep,setEditBankStep] = useState(1)

    function ldProvs(){
        axiosSend({
            url:'/fx/admin/liq-prov',
            method:"GET"
        }).then(res => {
            if (res.data.data && res.data.data.length > 0){
                let tmp:any = []
                res.data.data.forEach((prov:any)=>{
                    tmp.push({
                        providerId:prov.lip_code,
                        name:prov.lip_name,
                        email:prov.lip_email,
                        phone:prov.lip_phone,
                        // status: ucFirst(prov.lip_act_stat)
                        status: activeStatus(prov.lip_act_stat,t)
                    })
                    setProvs(tmp)
                    setProvsOri(tmp)
                })
            }
            setLoading(false)
        }).catch(err=>{
            console.log(err)
        })
    }

    useEffect(()=>{
        if (userDets && Object.keys(userDets).length > 0){
            let usrHasAccess = false
            userDets.apps.forEach((app:any)=>{
                if (app.APP_CAM){
                    usrHasAccess = true
                    ldProvs()
                }
                setHasAccess(usrHasAccess)
            })
        }
    },[userDets,i18n.language])

    useEffect(()=>{
        if (addProvStep === 1) setDisabledBack(true)
        if (addProvStep === 2 || addProvStep === 3 || addProvStep === 4) setDisabledBack(false)
        if (addProvStep === 5){
            setDisabledBack(true)
            setShowPanelTitle(false)
        }
    },[addProvStep])

    useEffect(()=>{
        if (editProvStep === 1) setDisabledBack(false)
        if (editProvStep === 2) setDisabledBack(true)
    },[editProvStep])

    useEffect(()=>{
        if (addBankStep === 1) setDisabledBack(false)
        if (addBankStep === 2) setDisabledBack(true)
    },[addBankStep])

    function srchProvs(qry:string){
        setSrchQry(qry)
        setProvs(provsOri.filter((prov:any)=>prov.name.toLowerCase().includes(qry.toLowerCase())))
    }

    function genStepTitle(step:number){
        if (step === 1) return t('common:liquidity_providers.basic_info')
        if (step === 2) return t('common:liquidity_providers.contact_info')
        if (step === 3) return t('common:liquidity_providers.other_info')
        if (step === 4) return t('common:liquidity_providers.bank_info')
    }

    function provDetsPanelHeader(){
        let retObj:any
        switch (detsView) {
            case 'prov-dets':
                retObj = {
                    backBtn:provDets.lip_name,
                    pnlTitle:'',
                    pnlSubText:''
                }
                break
            case 'manage-banks':
                retObj = {
                    backBtn:<BackButton onClick={()=>{setDetsView('prov-dets')}}/>,
                    pnlTitle:t('common:liquidity_providers.manage_bank_accts'),
                    pnlSubText:provDets.lip_name
                }
                break
            case 'edit-bank-acct':
                retObj = {
                    backBtn:<BackButton onClick={()=>{setDetsView('manage-banks')}} disabled={editBankStep === 2}/>,
                    pnlTitle:editBankStep === 2 ? '' : t('common:liquidity_providers.edit_bank_acct'),
                    pnlSubText:''
                }
                break
            case 'add-rates':
                retObj = {
                    backBtn:<BackButton onClick={()=>{setDetsView('prov-dets')}} disabled={addRateStep === 2}/>,
                    pnlTitle:addRateStep === 1 ? t('common:liquidity_providers.add_rate') : '',
                    pnlSubText:''
                }
                break
            case 'add-bank':
                retObj = {
                    backBtn:<BackButton onClick={()=>{setDetsView('prov-dets')}} disabled={disabledBack}/>,
                    pnlTitle:t('common:liquidity_providers.add_bank_acct'),
                    pnlSubText:''
                }
                break
            case 'edit-provider':
                retObj = {
                    backBtn:<BackButton onClick={()=>{setDetsView('prov-dets')}} disabled={disabledBack}/>,
                    pnlTitle:editProvStep === 1 ? t('common:liquidity_providers.edit_provider') : '',
                    pnlSubText:provDets.lip_name
                }
                break
            default:
                retObj = {
                    backBtn:<BackButton disabled={true}/>,
                    pnlTitle:'',
                    pnlSubText:''
                }
                break
        }
        return retObj
    }

    function getProvDets(provId:string){
        axiosSend({
            url:`/fx/admin/liq-prov/${provId}`,
            method:"GET"
        }).then(res => {
            setProvDets(res.data.data[0])

            //populate logs
            if (res.data.logs){
                let tmpLogs:any = []
                res.data.logs.forEach((log:any)=>{
                    tmpLogs.push({
                        timestamp:dayjs(log.aud_time).locale(i18n.language).format('DD MMM YYYY, HH:mm'),
                        user:log.aud_usr_id,
                        logDescription:log.aud_action,
                        reference:log.aud_id
                    })
                })
                setLogs(tmpLogs)
            }

            //populate rate history
            axiosSend({
                url:`/fx/admin/liq-prov-rate/all/${provId}`,
                method:'GET',
            }).then(rateRes => {
                let tmpRates:any = {
                    active:[],
                    archived:[]
                }
                if (rateRes.data.data){
                    rateRes.data.data.forEach((rate:any)=>{
                        if (rate.active === 'Y'){
                            tmpRates.active.push({
                                baseFlag:rate.base_cur_flag_file,
                                baseCurrency:rate.lpr_base_curr_name,
                                exchangeFlag:rate.exch_cur_flag_file,
                                exchangeCurrency:rate.lpr_exch_curr_name,
                                baseRate:rate.lpr_exch_buy_rate,
                                exchangeRate:rate.lpr_exch_sell_rate,
                                startDate:dayjs(rate.lpr_dt_from).locale(i18n.language).format('DD MMM YYYY, HH:mm'),
                                endDate:dayjs(rate.lpr_dt_to).locale(i18n.language).format('DD MMM YYYY, HH:mm')
                            })
                        }
                        if (rate.active === 'N'){
                            tmpRates.archived.push({
                                baseFlag:rate.base_cur_flag_file,
                                baseCurrency:rate.lpr_base_curr_name,
                                exchangeFlag:rate.exch_cur_flag_file,
                                exchangeCurrency:rate.lpr_exch_curr_name,
                                baseRate:rate.lpr_exch_buy_rate,
                                exchangeRate:rate.lpr_exch_sell_rate,
                                startDate:dayjs(rate.lpr_dt_from).locale(i18n.language).format('DD MMM YYYY, HH:mm'),
                                endDate:dayjs(rate.lpr_dt_to).locale(i18n.language).format('DD MMM YYYY, HH:mm')
                            })
                        }
                    })
                }
                setRatesHistory(tmpRates)
            })

            setShowProvDets(true)
        }).catch(err => {
            console.log(err)
        })
    }

    let tabs = [
        {
            tabName:t('common:liquidity_providers.provider_details'),
            tabComponent:<ProviderDets provDets={provDets} refreshProvs={()=>{ldProvs()}} switchView={(view)=>{setDetsView(view)}}/>
        },
        {
            tabName:t('common:liquidity_providers.rate_history'),
            tabComponent:<LPRateHistory active={ratesHistory.active} archived={ratesHistory.archived} switchView={(view)=>{setDetsView(view)}}/>
        },
        {
            tabName:t('common:general.activity_logs'),
            tabComponent:<AuditLog logDetails={logs}/>
        }
    ]

    //load bank details for editing
    function ldBankAcctDets(brokerId:string,acctCurr:string){
        axiosSend({
            url: `/fx/admin/part-acc-v2/${brokerId}?prtCurrCode=${acctCurr}`,
            method: 'GET'
        }).then(res => {
            if(res.data.data){
                setBankAcctDets(res.data.data[0])
                setDetsView('edit-bank-acct')
            }
        })
    }

    if (!hasAccess){
        return (
            <DashboardBaseLayout pageName="liquidity-providers">
                <UnauthorisedAccess/>
            </DashboardBaseLayout>
        )
    }

    return (
        <DashboardBaseLayout pageName="liquidity-providers">
            <div className="flex justify-between items-center mb-[16px]">
                <h6 className="heading-6-bold font-bold">{t('common:code_maintenance.liquidity_providers')}</h6>
                {Object.keys(userDets).length && userDets.userActions.includes('savLiqProvider') &&
                    <div className="flex justify-between items-center gap-[24px]">
                        <div>
                            <Button variant="primary" size="md" onClick={()=>{
                                setAddProvStep(1)
                                setShowAdd(true)
                                setShowPanelTitle(true)
                                setDisabledBack(true)
                            }}>{t('common:liquidity_providers.add_provider')}</Button>
                        </div>
                        <VDivider height={43}/>
                        <SearchField placeholder={t('common:liquidity_providers.search_providers')} value={srchQry} onChange={(e)=>{srchProvs(e.target.value)}}/>
                    </div>
                }
            </div>

            {loading &&
                <div className="bg-white rounded-[8px] h-[450px] flex justify-center items-center">
                    <LoadingSpinner variant={"primary"}/>
                </div>
            }
            {!loading &&
                <ProvidersTable providers={provs} callback={(e)=>{getProvDets(e.providerId)}} labelOverrides={{
                    emptyMsg:t('common:liquidity_providers.providers_empty_msg'),
                    name:t('common:general.name'),
                    email:t('common:login.email'),
                    phone:t('common:general.phone'),
                    status:t('common:general.status')
                }}/>
            }
            {showAdd &&
                <SidePanel closeFxn={()=>{setShowAdd(false)}} backBtnArea={<BackButton disabled={disabledBack || addProvStep === 1} onClick={()=>{setAddProvStep(addProvStep - 1)}}/>} title={`${showPanelTitle ? 'Add Liquidity Provider' : ''}`} subtext={genStepTitle(addProvStep)}>
                    <AddProvider step={addProvStep} stepFxn={(val)=>{setAddProvStep(val)}}/>
                </SidePanel>
            }
            {showProvDets &&
                <SidePanel closeFxn={()=>{
                    setShowProvDets(false)
                    setDetsView('prov-dets') //set this as the default view everytime the panel is closed
                    setEditProvStep(1)
                    setEditBankStep(1)
                    setShowDetsPanelTitle(true)
                }} backBtnArea={provDetsPanelHeader().backBtn} title={showDetsPanelTitle ? provDetsPanelHeader().pnlTitle : ''} subtext={provDetsPanelHeader().pnlSubText}>
                    {detsView === 'prov-dets' &&
                        <Tab tabs={tabs}/>
                    }
                    {detsView === 'manage-banks' &&
                        <ProviderBankAccounts provId={provDets.lip_code} getAcctDets={(e)=>{ldBankAcctDets(e.provId,e.curr)}} switchView={(e)=>{setDetsView(e)}}/>
                    }
                    {detsView === 'edit-bank-acct' &&
                        <EditProviderBankAcct acctDets={bankAcctDets} step={editBankStep} setStep={(e)=>{setEditBankStep(e)}}/>
                    }
                    {detsView === 'edit-provider' &&
                        <EditProvider provDets={provDets} step={editProvStep} stepFxn={(val)=>{setEditProvStep(val)}}/>
                    }
                    {detsView === 'add-rates' &&
                        <ProviderAddRates providerId={provDets.lip_code} step={addRateStep} stepFxn={(e)=>{setAddRateStep(e)}}/>
                    }
                    {detsView === 'add-bank' &&
                        <AddProviderBank providerId={provDets.lip_code} step={addBankStep} stepFxn={(e)=>{setAddBankStep(e)}} showPanelTitle={(e)=>{setShowDetsPanelTitle(e)}}/>
                    }
                </SidePanel>
            }
        </DashboardBaseLayout>
    )
}

export default LiquidityProviders